import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";

const ToggleButton = ({ className, onClick, toggle }) => {
  return (
    <button
      onClick={onClick}
      className={`${className} ${
        toggle ? "bg-[#E71CA5]" : "bg-[#E71CA5]"
      } flex relative w-[47px] h-5  transition-all duration-300 rounded-full border-0`}
    >
      <div
        className={`${
          !toggle ? "translate-x-[0] bg-white" : "translate-x-[140%] bg-white"
        } w-[20px]  h-full  my-auto relative  rounded-full  z-10 transition-all duration-300`}
      ></div>
      <div className="absolute inset-y-0 my-auto right-1 text-sm h-max">
        <MdDarkMode />
      </div>
      <div className="absolute inset-y-0 my-auto left-1 text-sm h-max">
        <MdOutlineLightMode />
      </div>
    </button>
  );
};

export default ToggleButton;
