import { useState } from "react";
import Footer from "../components/Footer/Footer";
import Nav from "../components/layout/Nav";
import MobileDrawer from "../components/MobileDrawer";

const Main = ({ children }) => {
  const [dark, setDark] = useState(false);
  const handleDark = () => {
    setDark(!dark);
  };
  if (dark) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <div
        className={`${
          dark ? "dark" : ""
        } bg-white dark:bg-[#252427] transition-all duration-300`}
      >
        <Nav
          toggle={toggleDrawer}
          dark={dark}
          handleDark={handleDark}
          navLinks={navLinks}
        />
        <MobileDrawer
          dark={dark}
          handleDark={handleDark}
          toggle={toggleDrawer}
          open={isOpen}
          navLinks={navLinks}
        />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Main;
const navLinks = [
  {
    id: 1,
    text: "Price Chart",
    path: "https://www.geckoterminal.com/bsc/pools/0x415afbbe3339813c366eb66a71d93b092478e0e6",
  },

  {
    id: 2,
    text: "White Paper",
    path: "https://docs.google.com/presentation/d/1E7XKFdAj0kb8MEuop7a4SLrCDw-pgtw_-A_jcBj79xQ/edit#slide=id.p",
  },
  {
    id: 3,
    text: "Get in touch",
    path: "mailto:lance@shinyheffers.io",
  },
];
