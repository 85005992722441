import React from "react";
import slot from "../assets/img/slot.png";
import dice from "../assets/img/dice.png";
import meetTeam from "../assets/img/teamBanner.png";
import Button from "../components/moduleComponents/Button";
import { IoMdWine } from "react-icons/io";

const MeetTeam = () => {
  return (
    <div className="pb-[45px] md:py-[45px] w-full  dark:bg-grayy dark-transition">
      <div className="container px-[6%] mx-auto">
        <div className="flex lg:flex-row flex-col">
          <div className="relative top-[3.5rem]  md:mx-0 sm:static sm:top-0 sm:right-0 xl:relative xl:top-0 xl:left-[50px] z-50">
            <img
              src={meetTeam}
              alt=""
              className="w-[250px] sm:w-auto m-auto z-50"
            />
          </div>
          <div className="flex md:relative xl:right-[50px]">
            <div className="why-invest-banner w-full flex bg-cover bg-no-repeat bg-center m-auto rounded-[10px] min-h-[332px] ">
              <div className="mx-auto mt-[50px] dark:text-white dark-transition max-w-[700px] w-full md:px-10 xl:px-20 ">
                <h3 className="font-[Arial] text-[26px] md:text-[47px] text-center mb-[27px] mt-[20px]">
                  Meet the team
                </h3>
                <div className="flex items-center sm:flex-row flex-col justify-center w-full gap-y-5 sm:gap-[50px]">
                  <Button
                    className={`w-[240px] 2xl:max-w-[277px]  min-h-[55px] 2xl:min-h-[67px] hover:mt-[-10px]`}
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/in/ltucker12/",
                        "_blank"
                      );
                    }}
                  >
                    <div className="flex items-center gap-2 justify-center font-['Lato'] text-base md:text-md block">
                      <span className="text-2xl">
                        <IoMdWine />
                      </span>
                      Lance Tucker
                    </div>
                  </Button>
                  <Button
                    className={`w-[240px] 2xl:max-w-[277px] min-h-[55px] 2xl:min-h-[67px] hover:mt-[-10px]`}
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/in/cleto253/",
                        "_blank"
                      );
                    }}
                  >
                    <div className="flex items-center gap-2 font-['Lato'] text-base md:text-md inline">
                      <span className="text-2xl">
                        <IoMdWine />
                      </span>
                      Alberto Hernandez
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetTeam;
