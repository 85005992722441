import React from "react";
import banner from "../assets/img/banner.png";
import banner_mobile from "../assets/img/banner_mobile.png";
import mission from "../assets/img/mission-light.png";

const ShinyHeffers = () => {
  return (
    <div className="py-[50px] bg-light dark:bg-black dark:bg-opacity-10 dark-transition shiny-heffers-bg">
      <div className="container mx-auto px-[1.8%]">
        <div className="mb-8">
          <img
            src={banner}
            alt="imgs"
            className="w-840 h-302 hidden md:block"
          />
          <img
            src={banner_mobile}
            alt="imgs"
            className="md:hidden w-full mx-auto"
          />
        </div>
      </div>
      <div className="container mx-auto px-[6%]">
        <div className="flex lg:flex-row flex-col gap-9 lg:text-left xl:text-left text-center items-center w-full">
          <div className="flex-1 lg:max-w-none max-w-xs justify-items-center grid md:justify-items-start ">
            <img src={mission} alt="imgs" className="w-10/12" />
          </div>
          <div className="flex flex-col flex-1">
            <h3 className="text-[26px] md:text-[47px] font-bold font-['Arial'] dark:text-white dark-transition text-center ">
              Mission Statement
            </h3>
            <p className="text-[16px] text-center xl:text-[18px] font-['Lato'] dark:text-white dark-transition md:text-left leading-10">
              Shiny Heffers’ Mission is to educate the next generation of retail
              Investors World wide and help them gain financial freedom through
              high returns on low initial risk. With continued support, we’ll be
              funding projects in the Metaverse and real world utility including
              agriculture, land development and other investments. Are you ready
              for this SHIT?
              {/* Shiny Heffers Investment Token is a utility token that will have
              real life application in the Metaverse and beyond. Our mission is
              to educate the next generation of investors world wide and help
              investors gain financial freedom that will truly make a
              difference. Are you ready for this SHIT? */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShinyHeffers;
