import { Link } from "react-router-dom";

import Button from "../moduleComponents/Button";
import logo from "../../assets/img/logo.png";
import ToggleButton from "../moduleComponents/ToggleButton";
import { BiWallet } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import toggler from "../../assets/img/toggler-icon.png";

const Nav = ({ navLinks, handleDark, dark, toggle }) => {
  return (
    <div className="w-full py-4 flex items-center bg-[#433B78] ">
      <div className="container mx-auto flex px-[6%] items-center">
        <div className="flex gap-[33px] items-center">
          <Link to={`/`}>
            <img src={logo} alt="logo" />
          </Link>

          <ToggleButton
            className={`md:flex hidden`}
            toggle={dark}
            onClick={handleDark}
          />
        </div>

        <ul className="md:flex hidden gap-8 items-center mx-auto xl:ml-[170px] font-['Lato'] text-sm font-bold">
          {navLinks.map(({ id, text, path }) => {
            return (
              <li key={id}>
                <a
                  className="hover:text-[#E71CA5] transition-all text-white duration-300 capitalize"
                  href={path}
                  target="_blank"
                >
                  {text}
                </a>
              </li>
            );
          })}
        </ul>

        <Button
          className={`w-[155px] md:w-[163px] h-[40px] md:h-[50px] ml-auto`}
          onClick={() => {
            window.open(
              "https://pancakeswap.finance/swap?outputCurrency=0x18c8845Af8Bc9669b2F3af2462567cE35263C34a",
              "_blank"
            );
          }}
        >
          <div className="flex items-center text-[10px] md:text-[14px] gap-2 font-['Lato'] md:text-sm font-bold">
            <BiWallet />
            Pancake Swap
          </div>
        </Button>

        <button
          className="text-2xl text-[#E71CA5] md:hidden block"
          onClick={toggle}
        >
          <div className="w-[40px] gradient text-2xl h-[40px] rounded-full bg-white flex items-center justify-center ml-1">
            <img src={toggler} alt="" />
          </div>

          {/* <GiHamburgerMenu /> */}
        </button>
      </div>
    </div>
  );
};

export default Nav;
