import React from "react";
import { FiInstagram } from "react-icons/fi";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import instagram from "../../assets/img/instagram.png";
import facebook from "../../assets/img/facebook.png";
import linkdin from "../../assets/img/linkedin.png";

const Footer = () => {
  return (
    <div className="py-[46px] bg-[#2F2E4C]">
      <div className="main-container flex flex-col">
        <div className="m-auto flex gap-6 ">
          {icons.map(({ id, icon, path }) => {
            return (
              <a
                href={path}
                target="_blank"
                key={id}
                className="w-[49px] md:w-[72px] gradient text-2xl h-[49px] md:h-[72px] rounded-full bg-white flex items-center justify-center"
              >
                {icon}
              </a>
            );
          })}
        </div>{" "}
        <div className="text-center text-lg mt-8 text-white font-['Lato']">
          © 2022 Shiny Heffers
        </div>
      </div>
    </div>
  );
};

export default Footer;

const icons = [
  {
    id: 1,
    path: "https://www.instagram.com/shinyheffers/",
    icon: (
      <img
        src={instagram}
        alt="img"
        className="w-[24px] h-[24px] md:w-[36px] md:h-[36px]"
      />
    ),
  },
  {
    id: 2,
    path: "https://www.facebook.com/shinyheffers",
    icon: (
      <img
        src={facebook}
        alt="img"
        className="w-[14px] h-[27px] md:w-[21px] md:h-[40px]"
      />
    ),
  },
  {
    id: 3,
    path: "https://www.linkedin.com/company/shinyheffers",
    icon: (
      <img
        src={linkdin}
        alt="img"
        className="w-[26px] h-[26px] md:w-[38px] md:h-[38px]"
      />
    ),
  },
];
