import React from "react";
import light from "../assets/img/light4.png";
import dark from "../assets/img/darkscem.png";

const WhyInvest = () => {
  return (
    <div className="mt-[140px] md:mt-0 md:pt-[80px] pb-[10px] md:pb-[100px] w-full dark:bg-grayy">
      <div className="container mx-auto px-[6%] relative">
        <div className="grid items-center xl:place-items-stretch grid-cols-1 xl:grid-cols-4">
          <div className="py-[80px] xl:py-[20px] xl:order-none order-last xl:col-span-3 px-[10px] md:px-[51px] why-invest-banner bg-cover bg-no-repeat bg-center text-center md:text-start ">
            <h3 className="font-[Arial] pt-20 xl:pt-0 text-[26px] md:text-[47px] md:mb-[36px] dark:text-white dark-transition text-center xl:text-left">
              Why invest in
              <br /> Shiny Heffers?
            </h3>
            <p className="xl:max-w-[430px] font-['Lato'] text-[14px] md:text-[16px] md:leading-[40px] dark:text-white text-center xl:text-left dark-transition">
              Our mission is “The SHIT” - we’ll be investing profits in our real
              world utilities and the Metaverse. Our plan is to take our
              investors to the next generation of human social interaction;
              giving them a means to purchase land and other items directly in
              the Metaverse. The reality of the coin is simple; pump it up and
              some make some money!
            </p>
          </div>
          <div className="h-full flex justify-center xl:block">
            <div className="relative xl:absolute w-max right-0 top-[53%] mb-[-380px] xl:mb-0 translate-y-[-50%] flex justify-end">
              <img
                className="dark:hidden block object-contain w-full h-full"
                src={light}
                alt="img"
              />
              <img
                className="dark:block hidden object-contain w-full h-full"
                src={dark}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyInvest;
