import React from "react";
const ContractAddress = () => {
  return (
    <div className="py-[20px] md:py-[80px] w-full dark:bg-[#272529]">
      <div className="container mx-auto px-[6%]">
        <div className="w-full px-4 md:px-8 flex min-h-[186px] contract-address bg-cover bg-no-repeat bg-center">
          <div className="my-auto flex flex-col w-full">
            <h3 className="text-[26px] md:text-5xl text-center  font-bold md:font-normal md:text-center break-words font-['Arial'] dark:text-white dark-transition mb-2 lg:pr-4 ">
              Coin Contract address
              {/* <br className="md:hidden" />  */}
            </h3>
            <p className='w-full font-["Lato"] font-bold	 text-["18px"] md:text-[30px] break-words flex-wrap flex justify-center dark:text-white dark-transition'>
              0x18c8845Af8Bc9669b2F3
              <br className="lg:hidden" />
              af2462567cE35263C34a
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractAddress;
