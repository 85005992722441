import React from "react";
import rdImg from "../assets/img/cards-dark.png";
const RoadMap = () => {
  return (
    <div className="py-[61px] roadmap-bg w-full bg-light dark:bg-[#252427] dark-transition">
      <div className="container mx-auto px-[6%]">
        <h3 className="font-[Arial] text-[26px] md:text-[47px] text-center dark:text-white dark-transition ] pb-[40px] md:pb-[100px]">
          Project Roadmap V.1
        </h3>
        {/* <h2 className="font-['BowlbyOneSC'] text-[26px] md:text-[47px] capitalize mb-[15px] md:mb-[70px] text-center mx-auto dark:text-white dark-transition">
          the shiny heffers <br /> road map
        </h2> */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-10 lg:gap-y-8">
          {roadmap.map(({ id, title, li }) => {
            return (
              <div
                className="w-full flex sm:flex-row flex-col text-center sm:text-left items-center sm:items-start gap-5 lg:gap-[56px] mx-auto"
                key={id}
              >
                <div className="border-[6px] flex relative border-white dark:border-black dark-transition rounded-b-[45px] rounded-tl-[45px]">
                  <img
                    className="border-[4px] border-[#15D9E8] rounded-b-[45px] rounded-tl-[45px]"
                    src={rdImg}
                    alt="roadmap"
                  />
                  <p className="absolute inset-0 m-auto text-white z-10 w-max h-max text-[47px] font-BowlbyOneSc">
                    {id}
                  </p>
                </div>
                <div className="dark:text-white dark-transition">
                  <h3 className="font-[Arial] text-2xl lg:text-[36px] mb-3">
                    {title}
                  </h3>
                  <ul className="flex flex-col">
                    {li.map((text, index) => (
                      <li key={index}>
                        <p className="text-sm md:text-lg font-['Lato']">
                          {text}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RoadMap;

const roadmap = [
  {
    id: 1,
    title: "Phase one",
    li: [
      "Set Up Social Media Platforms",
      "Develop Website",
      "Implement marketing strategies ",
      "Launch Season 1 nfts on opensea.io",
      "Prizes for early investors",
    ],
  },
  {
    id: 2,
    title: "Phase two",
    li: [
      "List on CoinMarketCap",
      "List on Coin Gecko",
      "Exchange Listing",
      "Pancake swap launch",
      "Hotbit",
      "Bitmart",
      "Probit",
      "Crypto.com",
      "Binance",
    ],
  },
  {
    id: 3,
    title: "Phase three",
    li: ["AMA’s", "Social media ads", "Influencers"],
  },
  {
    id: 4,
    title: "Phase four",
    li: [
      "Launch Hefferswap",
      "Launch Season 2 NFT’s on opensea.io",
      "Integrate into the Metaverse",
      "Tesla giveaway",
      "Shiny Heffers Merch",
      "NFT Farming",
      "Play 2 Earn",
    ],
  },
];
