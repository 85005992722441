import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import slide1 from "../assets/img/slide1.png";
import slide2 from "../assets/img/slide2.png";
import slide3 from "../assets/img/slide3.png";
import slide4 from "../assets/img/slide4.png";
import slide5 from "../assets/img/slide5.png";
import slide6 from "../assets/img/slide6.png";

import Button from "../components/moduleComponents/Button";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const GoonSquad = () => {
  SwiperCore.use([Autoplay, Navigation]);
  const swiperRef = useRef(null);

  const [currentIndex, updateCurrentIndex] = useState(0);
  console.log(currentIndex, "swiperindex");

  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1280: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
    },
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      updateIndex();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const updateIndex = useCallback(
    () => updateCurrentIndex(swiperRef.current.swiper.realIndex),
    []
  );
  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex);
      }
    };
  }, [updateIndex, swiperRef]);

  return (
    <div className="pt-[30px] pb-[30px] md:pb-[100px] w-full dark:bg-[#272529] overflow-hidden">
      <div className="container mx-auto px-[6%]">
        <h3 className="font-[Arial] text-[26px] md:text-[47px] text-center dark:text-white dark-transition ] pb-[40px] md:pb-[100px]">
          Meet our NFT’s “The Goon Squad”
        </h3>

        <div className="w-full relative">
          <Swiper
            loop="true"
            modules={[Navigation, Pagination]}
            slidesPerView={1}
            spaceBetween={0}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            navigation
            pagination={{ clickable: true }}
            {...params}
            ref={swiperRef}
            className=""
          >
            {slides.map(({ id, img, title, number, url }) => {
              return (
                <SwiperSlide className="py-6 " key={id}>
                  <div className="flex flex-col items-center rounded-[45px] bg-white dark:bg-black overflow-hidden dark:text-white dark-transition shadow-lg  w-[270px] md:w-[300px] mx-auto">
                    <div className="">
                      <img
                        className="border-[5px] border-[#A0188D] rounded-[45px] "
                        src={img}
                        alt="nft"
                      />
                    </div>

                    <div className="py-[26px] swiper-content px-5 w-100 text-2xl font-Bold flex flex-col">
                      <h4 className="font-['Lato'] font-bold whitespace-nowrap	">
                        {title}
                      </h4>
                      <p className="text-2xl font-['Lato'] font-bold">
                        {number}
                      </p>

                      <Button
                        className={`ml-auto w-max`}
                        px={"px-3"}
                        height={40}
                        onClick={() => {
                          window.open(url, "_blank");
                        }}
                      >
                        <span className="flex items-center">
                          <span className="text-[16px] font-bold font-Lato m-0">
                            opensea.io
                          </span>
                          <BsFillArrowRightCircleFill className="ml-2" />
                        </span>
                      </Button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <button
            onClick={goNext}
            className="left-btn w-10 lg:w-[49px] h-[281px] lg:h-[281px] dark:bg-black dark:text-[#E71CA5] dark-transition absolute inset-y-0 right-1 md:right-[-30px] 2xl:-right-20 bg-white my-auto z-20 flex shadow-card rounded-[13px] shadow-md hidden md:flex"
          >
            <span className="w-max h-max text-3xl m-auto">
              <GoChevronRight />
            </span>
          </button>
          <button
            onClick={goPrev}
            className="right-btn w-10 lg:w-[49px] h-[281px] lg:h-[281px] dark:bg-black dark:text-[#E71CA5] dark-transition flex shadow-card rounded-[13px] absolute inset-y-0 left-1 md:left-[-30px] 2xl:-left-20 bg-white my-auto z-20 shadow-md hidden md:flex"
          >
            <span className="w-max h-max text-3xl m-auto">
              <GoChevronLeft />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoonSquad;

const slides = [
  {
    id: 1,
    img: slide1,
    title: "Goon Squad Member",
    number: "#1",
    url: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/102613449108789073265656580262072546874934287163583578797988534460135178764289",
  },
  {
    id: 2,
    img: slide2,
    title: "Goon Squad Member",
    number: "#120",
    url: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/102613449108789073265656580262072546874934287163583578797988534590977062469633",
  },
  {
    id: 3,
    img: slide3,
    title: "Goon Squad Member",
    number: "#532",
    url: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/102613449108789073265656580262072546874934287163583578797988535048373899624449",
  },
  {
    id: 4,
    img: slide4,
    title: "Goon Squad Member",
    number: "#105",
    url: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/102613449108789073265656580262072546874934287163583578797988534574484388052993",
  },
  {
    id: 5,
    img: slide5,
    title: "Goon Squad Member",
    number: "#122",
    url: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/102613449108789073265656580262072546874934287163583578797988534593176085725185",
  },
  {
    id: 6,
    img: slide6,
    title: "Goon Squad Member",
    number: "#589",
    url: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/102613449108789073265656580262072546874934287163583578797988534596474620608513",
  },
];
