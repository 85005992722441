import { Link } from "react-router-dom";
import ShinyHeffers from "./ShinyHeffers";
import ContractAddress from "./ContractAddress";
import GoonSquad from "./GoonSquad";
import WhyInvest from "./WhyInvest";
import RoadMap from "./RoadMap";
import MeetTeam from "./MeetTeam";
const AdminView = (props) => {
  return (
    <>
      <ShinyHeffers />
      <ContractAddress />
      <GoonSquad />
      <WhyInvest />
      <RoadMap />
      <MeetTeam />
    </>
  );
};

export default AdminView;
