import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import Button from "./moduleComponents/Button";
import ToggleButton from "./moduleComponents/ToggleButton";
import { BiWallet } from "react-icons/bi";
import drawerToggle from "../assets/img/drawer-close.png";
import instagram from "../assets/img/instagram.png";
import facebook from "../assets/img/facebook.png";
import linkdin from "../assets/img/linkedin.png";

const MobileDrawer = ({ open, toggle, navLinks, dark, handleDark }) => {
  return (
    <>
      <Drawer open={open} onClose={toggle} direction="right" zIndex={99999}>
        <div className="py-5 flex flex-col h-full bg-[#433B78]">
          <div className="flex items-center">
            <Button
              onClick={() => {
                window.open(
                  "https://pancakeswap.finance/swap?outputCurrency=0x18c8845Af8Bc9669b2F3af2462567cE35263C34a",
                  "_blank"
                );
              }}
              className={`max-w-[157px] h-[40px] ml-auto`}
            >
              <div className="flex text-sm gap-2">
                <span className="flex items-center">
                  <BiWallet />
                </span>
                Pancake Swap
              </div>
            </Button>
            <img
              src={drawerToggle}
              alt="drawerToggle"
              onClick={() => {
                toggle();
              }}
              className="cursor-pointer"
            />
          </div>
          <hr className="mt-4" />
          <ul className="flex flex-col gap-y-4 mb-8 mt-2">
            {navLinks.map(({ id, text, path }) => {
              return (
                <>
                  <li key={id} className="mx-auto">
                    <a
                      target="_blank"
                      className="capitalize text-[18px] ml-0 hover:ml-2 w-full text-white duration-300 transition-all"
                      href={path}
                    >
                      {text}
                    </a>
                  </li>
                  <hr className="" />
                </>
              );
            })}
          </ul>
          <div className="ml-auto mt-auto mb-3 flex gap-2 mr-3 ">
            {icons.map(({ id, icon, path }) => {
              return (
                <a
                  href={path}
                  target="_blank"
                  key={id}
                  className="w-[35px] gradient text-2xl h-[35px] rounded-full bg-white flex items-center justify-center"
                >
                  {icon}
                </a>
              );
            })}
          </div>{" "}
          <hr />
          <ToggleButton
            toggle={dark}
            onClick={handleDark}
            className="ml-auto mr-2 mt-2"
          />
        </div>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
const icons = [
  {
    id: 1,
    path: "https://www.instagram.com/shinyheffers/",
    icon: <img src={instagram} alt="img" className="w-[15px] h-[15px]" />,
  },
  {
    id: 2,
    path: "https://www.facebook.com/shinyheffers",
    icon: <img src={facebook} alt="img" className="w-[10px] h-[20px]" />,
  },
  {
    id: 3,
    path: "https://www.linkedin.com/company/shinyheffers/",
    icon: <img src={linkdin} alt="img" className="w-[19px] h-[19px]" />,
  },
];
